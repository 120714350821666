<template>
  <div @click="toggleAccordion" class="divider">
    <Icon
      name="chevronForwardOutline"
      :class="[{'arrow-rotate': open }, {'arrow-red': arrowRed}, 'arrow-forward']"
    />
    <div class="main-text"> {{ headline }}</div>
    <slot name="additional-headline-info"></slot>
  </div>
  <div v-if="open">
    <div class="white-space"></div>
    <slot name="opened-content"></slot>
  </div>
</template>


<script>
export default {
  name: 'base-accordion',
  data() {
    return {
      open: false,
    }
  },
  props: {
    headline: String,
    arrowRed: Boolean,
  },
  methods: {
    toggleAccordion() {
      this.open = !this.open
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/main.scss';

.divider {
  display: grid;
  grid-template-columns: 40px 8fr 3fr;
  align-items: center;
}

.arrow-forward {
  height: 30px;
  width: 30px;
  stroke: white;
  transition: all 0.2s ease;
  background: #{$col-button-green};
  padding: 5px;
  border-radius: 50px;
}

.arrow-rotate {
  transform: rotateZ(90deg);
}

.arrow-red {
  background: #{$col-red} !important;
}

.white-space {
  height: 10px;
}

</style>