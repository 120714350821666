<!--

      This component defines a tab bar, which resides in the header of a page.
      It is scrollable sideways if it exceeds the screens width.

 -->

<template>
  <div class="tabslider-outside">
    <div class="tabslider-inside">
      <div class="the-tab" v-for="t in tabs" @click="$emit('clicked', t)" :key="t">
        <div :class="[{ 'tab-active': tab === t  }, 'sub-tab', 'tab-text']" @click="$emit('clicked', t)">{{ t }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'tabswiper',
  props: ['tabs', 'tab'],
}
</script>

<style lang="scss" scoped>
@import './../theme/main.scss';

.sub-tab {
  display: inline-block;
  width: auto;
}

.sub-tab {
  padding: 5px 20px;
}

.tab-active {
  color: white !important;
  background: $col-font-green;
}

.tabslider-outside {
  display: block;
  overflow-x: scroll;
  overflow-y: hidden;
  margin: 0 -5px 20px -5px;
  text-align: center;
  overscroll-behavior-x: contain;
}
.tabslider-outside::-webkit-scrollbar {
  display: none;
}

.tabslider-inside {
  display: inline-block;
  overflow-x: hidden;
  overflow-y: hidden;
  white-space: nowrap;
  background: $col-button-green;
  border-radius: $border-radius;
  margin: 0 10px;
}

.the-tab {
  display: inline-block;
  text-align: center;
  white-space: nowrap;
}

.tab-text {
  @extend .main-text;
  color: #{$col-font};
  border-radius: $border-radius;
  transition: ease-in-out .2s;
}
</style>
