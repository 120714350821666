<template>
    <div
      v-for="(product, index) in items.products"
      :key="product.product_id"
    >
      <transition name="fade">
        <div class="product-list-item" :class="{ 'first-product-list-item': index === 0 }" v-show="product.visible || ordered">
          <div class="flex-area">
            <div class="product-img-area" @click="showProductAlternatives(product)">
              <img class="product-img" :src="product.img_url + '?output-format=jpeg&output-quality=70&resize=176px%3A176px'" alt="" />
            </div>
            <div class="upper-right-area">
              <div class="missing-count missing-tag" v-if="product.unavailable" @click="showProductAlternatives(product)">
                <div class="missing-red"></div><div class="secondary-text">nicht verfügbar</div>
              </div>
              <div class="product-name-area">
                <div class="product-name" @click="showProductAlternatives(product)">
                  <div>{{ productOrIngredientName(product) }}</div>
                </div>
                  <Icon v-show="!ordered" name="foodableChevronForward" class="product-open-icon" />
              </div>
              <div class="price-amount-area">
                <Price :price="product.price" />
                <AmountSelector
                  v-if="!ordered"
                  :amount="product.quantity"
                  :product="product"
                  :key="product.quantity"
                />
                <div v-if="ordered" class="secondary-text-gray">{{product.quantity}} mal gekauft</div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
</template>

<script>
import Price from '@/components/Price.vue'
import AmountSelector from '@/components/AmountSelector.vue'

export default {
  components: {
    Price,
    AmountSelector
  },
  data () {
    return {
      products: []
    }
  },
  props: {
    items: Object,
    ordered: Boolean
  },
  methods: {
    productOrIngredientName (product) {
      if (product.product_name && product.product_name.length > 0) {
        return product.product_name
      } else {
        return product.shopping_ingredient_name
      }
    },
    showProductAlternatives (item) {
      if (!this.ordered) {
        this.$store.dispatch('save', { key: 'productreplace', value: item })
        this.$store.dispatch('goTo', 'product')
      }
    },
    timeout(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }
  }
}
</script>


<style lang="scss" scoped>
@import '@/theme/main.scss';

.category-price {
  text-align: right;
}

.missing-tag {
  margin-top: -5px;
  margin-bottom: 5px;
  margin-left: -5px;
}

.flex-area {
  display: flex;
  align-items: center;
  width: 100%;
}

.upper-right-area {
  width: 100%;
  padding-left: 5px;
}

.price-amount-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.product-name-area {
  display: grid;
  grid-template-columns: 1fr 10px;
}

.product-open-icon {
  width: 10px;
  height: 20px;
}

.product-img-area {
  height: 100%;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.product-img {
  width: 80px;
  height: 80px;
  object-fit: contain;
  margin: auto;
}

.product-list-item {
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: 10px;
  border-top: solid 1px $col-lighter-gray-font;
  margin-top: 10px;
}

.first-product-list-item {
  border-top: none;
  padding-top: 0px;
  margin-top: 0px;
}

.product-name {
  @extend .main-text;
  margin-bottom: 10px;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
}

</style>