<!--

      This component shows one category of the shopping list. It displays its
      name (e.g. Gemüse) and its subordinate entries (e.g. Tomate, Gurke).
      Furthermore, it is expandable like an accordion.

 -->

<template>
  <div class="white-box-small">
  <BaseAccordion :headline="items.name" :arrowRed="items.hasUnavailable">
    <template v-slot:additional-headline-info>
      <Price :price="this.totalPrice" class="text-right" :key="this.totalPrice"/>
    </template>
    <template v-slot:opened-content>
      <ShoppingListItemContent :items="items" :ordered="ordered">
      </ShoppingListItemContent>
    </template>
  </BaseAccordion>
  </div>
</template>

<script>
 import Price from '@/components/Price.vue'
 import AmountSelector from '@/components/AmountSelector.vue'
 import BaseAccordion from '@/components/accordions/BaseAccordion.vue'
 import ShoppingListItemContent from '@/components/accordions/ShoppingListItemContent.vue'

export default {
  name: 'partiallist',
  props: ['items', 'ordered'],
  components: {
    Price,
    AmountSelector,
    BaseAccordion,
    ShoppingListItemContent
  },
  computed: {
    totalPrice () {
      if (this.items.products && this.items.products.length > 0) {
        let total = 0
        this.items.products.forEach(p => {
          total += (p.price * p.quantity)
        })
        return total
      }
    }
  }
}
</script>
